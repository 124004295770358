import { View } from 'react-native'
import { Collapsible } from '../../../../components/Collapsible'
import { H4 } from '../../../../components/TextComponents'
import { ProjectNotificationLog } from '../../../../components/ProjectNotificationLog'
import { AktenIdentifier } from '@dpa/common/dist'

export interface ProjectNotificationCollapsibleProps {
  identifier: AktenIdentifier
}

export function ProjectNotificationCollapsible({ identifier }: ProjectNotificationCollapsibleProps) {
  return (
    <View style={{ paddingTop: 10 }}>
      <Collapsible
        summary={
          <H4
            style={{
              fontSize: 20,
              marginLeft: 8,
              marginHorizontal: 10,
              marginVertical: 10,
            }}
          >
            Projektbenachrichtigungen
          </H4>
        }
      >
        <ProjectNotificationLog identifier={identifier} />
      </Collapsible>
    </View>
  )
}
