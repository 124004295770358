import formLabels_de from './de/labels.json'
import formNotes_de from './de/notes.json'
import tableHeaders_de from './de/tableHeaders.json'

import enumTranslations_de from './de/enum.json'

const translationPrefixes = {
  labels: 'form_label_',
  notes: 'form_note_',
  tableHeaders: 'tabTitle_',
  stateHistoryText: 'stateHistoryText_',
  transitionFiredText: 'transitionFiredText_',
  btnTxt: 'btnTxt_',
} as const

export function addPrefix<TTyp extends keyof typeof translationPrefixes, TKey extends string>(
  typ: TTyp,
  key: TKey
): `${(typeof translationPrefixes)[TTyp]}${TKey}` {
  return `${translationPrefixes[typ]}${key}`
}

export function addKeyPrefix<
  TTyp extends keyof typeof translationPrefixes,
  TKeys extends string,
  TValues extends string,
>(typ: TTyp, obj: Record<TKeys, TValues>) {
  const entries = Object.entries(obj)
  const prefixedEntries = entries.map(([key, value]) => [addPrefix(typ, key), value])

  const prefixedObj = Object.fromEntries(prefixedEntries) as {
    [key in TKeys as ReturnType<typeof addPrefix<TTyp, key>>]: (typeof obj)[key]
  }

  return prefixedObj
}

export type CanonicalFormLabels = keyof typeof formLabels_de

// TODO: this might not be save, as typescript is TS is not strictly typed
//       But they are loaded from json, so it *should* be fine I guess
export const canonicalFormLabels = Object.keys(formLabels_de) as CanonicalFormLabels[]

export type FormLabelKeys = ReturnType<typeof addPrefix<'labels', CanonicalFormLabels>>

export function asFormLabel(candidate: string): CanonicalFormLabels | undefined {
  if ((canonicalFormLabels as string[]).includes(candidate)) {
    return candidate as CanonicalFormLabels
  }
  return undefined
}

export type OnlyValideLabels<T> = T extends CanonicalFormLabels ? T : never

export type CanonicalFormNotes = keyof typeof formNotes_de
export const canonicalFormNotes = Object.keys(formNotes_de) as CanonicalFormNotes[]
export type FormNotesKeys = ReturnType<typeof addPrefix<'notes', CanonicalFormNotes>>


export type CanonicalTableHeaders = keyof typeof tableHeaders_de
export const canonicalTableHeaders = Object.keys(tableHeaders_de) as CanonicalTableHeaders[]
export type TableHeadersKeys = ReturnType<typeof addPrefix<'notes', CanonicalTableHeaders>>

export type CanonicalEnumTranslations = keyof typeof enumTranslations_de
export const canonicalEnumTranslations = Object.keys(enumTranslations_de) as CanonicalEnumTranslations[]
export type EnumTranslationsKeys = ReturnType<typeof addPrefix<'notes', CanonicalEnumTranslations>>

export function BuildTranslationMap(
  formLabels: Record<CanonicalFormLabels, string>,
  formNotes: Record<CanonicalFormNotes, string>,
  tableHeaders: Record<CanonicalTableHeaders, string>,
  enumTranslations: Record<CanonicalEnumTranslations, string>,
) {
  return {
    ...addKeyPrefix('labels', formLabels),
    ...addKeyPrefix('notes', formNotes),
    ...addKeyPrefix('tableHeaders', tableHeaders),
    ...enumTranslations,

    ...addKeyPrefix('stateHistoryText', {
      workOn_Vertrieb2PE: "Das Projekt wird für die Übergabe von Vertrieb an PE bearbeitet.",
      workOn_PE2Vertrieb: "Das Projekt wird für die Übergabe von PE an Vertrieb bearbeitet.",
      workOn_Vertrieb2ENG: "Das Projekt wird für die Übergabe von Vertrieb an ENG bearbeitet.",
      workOn_ENG2Vertrieb: "Das Projekt wird für die Übergabe von ENG an Vertrieb bearbeitet.",
      workOn_Vertrieb2Projektleitung: "Das Projekt wird für die Übergabe von Vertrieb an Projektleitung bearbeitet.",
      workOn_Projektleitung2SuW: "Das Projekt wird für die Übergabe von Projektleitung an O&M bearbeitet.",
      workOn_SuW: "Das Projekt befindet in der finalen Abnahme durch O&M.",
      type_Elektifizierung_AC: "Es handelt sich um ein Elektifizierung AC Projekt.",
      type_Elektifizierung_DC: "Es handelt sich um ein Elektifizierung DC Projekt.",
      type_komplexe_DC_inclIBN: "Es handelt sich um ein Komplexe DC inkl. IBN (mit PEENG) Projekt.",
      type_standart_DC_inclIBN: "Es handelt sich um ein Standardisierte DC inkl. IBN (ohne PEENG) Projekt.",
      type_auslieferung_DC: "Es handelt sich um ein Auslieferung DC (ohne Konfig) Projekt.",
      type_ac_inc_config: "Es handelt sich um ein AC inc. Konfig Projekt.",
      preHandover_PE2Vertrieb: "Die Akte wird vor der Übergabe von PE an Vertrieb überprüft.",
      decision_StandortkonzeptOrUmsetzungsplanung: "Projektartenermittlung",
      decision_DCS_LIS_A: "Projektartenermittlung",
      decision_DCS_LIS_B: "Projektartenermittlung",
      decision_dienstleitungOrIbnKonfig: "Projektartenermittlung",
      decision_Standortkonzept: "Projektartenermittlung",
      decision_UmsetzungsplanungBeauftragt: "Projektartenermittlung",
      decision_UmsetzungBeauftragt: "Projektartenermittlung",
      decision_DL_verkauft: "Projektartenermittlung",
      handover_Vertrieb2PE: "Die Übergabe von Vertrieb an PE wurde angefragt.",
      handover_PE2Vertrieb: "Die Übergabe von PE an Vertrieb wurde angefragt.",
      handover_Vertrieb2ENG: "Die Übergabe von Vertrieb an ENG wurde angefragt.",
      handover_ENG2Vertrieb: "Die Übergabe von ENG an Vertrieb wurde angefragt.",
      handover_Vertrieb2Projektleitung: "Die Übergabe von Vertrieb an Projektleitung wurde angefragt.",
      handover_Projektleitung2SuW: "Die Übergabe von Projektleitung an O&M wurde angefragt.",
      terminated_preUmsetzungsplanung: "Da keine Umsetzungsplanung beauftragt wurde ist die Akte geschlossen.",
      terminated_preUmsetzung: "Da keine Umsetzung beauftragt wurde ist die Akte geschlossen.",
      terminated_inBetrieb: "Das Projekt ist in Betrieb.",
    }),
    ...addKeyPrefix('transitionFiredText', {
      decision_standortkonzept_NEIN: 'Es wurde weder eine Umsetzungsplanung noch ein Standortkonzept verkauft.',
      decision_standortkonzept_JA: 'Es wurde eine Umsetzungsplanung oder ein Standortkonzept verkauft.',
      decision_DCS_LIS_B_NEIN: 'DC LIS ist nicht Bestandteil.',
      decision_DCS_LIS_B_JA: 'DC LIS ist Bestandteil.',
      decision_dienstleitungOrIbnKonfig_JA: 'Es wurde neben IBN oder Konfig eine oder mehrere weitere Dienstleistungen verkauft.',
      decision_dienstleitungOrIbnKonfig_NEIN: 'Es wurde IBN oder Konfig verkauft.',
      decision_DCS_LIS_A_NEIN: 'DC LIS ist nicht Bestandteil.',
      decision_DCS_LIS_A_JA: 'DC LIS ist Bestandteil.',
      decision_DL_NEIN: 'Es wurde keine DL verkauft.',
      decision_DL_JA: 'Es wurde eine DL verkauft.',
      start_Elektifizierung_AC: 'Es handelt sich um ein Elektifizierung AC Projekt.',
      start_Elektifizierung_DC: 'Es handelt sich um ein Elektifizierung DC Projekt.',
      decision_Standortkonzept_JA: 'Es wurde ein Standortkonzept verkauft',
      decision_Standortkonzept_NEIN: 'Es wurde kein Standortkonzept verkauft',
      start_komplexe_DC_inclIBN: 'Es handelt sich um ein Komplexe DC inkl. IBN (mit PEENG) Projekt.',
      start_type_standart_DC_inclIBN: 'Es handelt sich um ein Standardisierte DC inkl. IBN (ohne PEENG) Projekt.',
      start_type_auslieferung_DC: 'Es handelt sich um ein Auslieferung DC (ohne Konfig) Projekt.',
      start_type_ac_inc_config: 'Es handelt sich um ein AC inc. Konfig Projekt.',
      Vertrieb2PE_request: 'Die Übergabe von Vertrieb an PE wurde angefragt.',
      Vertrieb2PE_approved: 'Vertrieb an PE wurde angenommen.',
      Vertrieb2PE_rejected: 'Vertrieb an PE wurde abgelehnt.',
      PE2Vertrieb_preRequest: 'Eine überprüfung von PE an Vertrieb wurde angefragt.',
      PE2Vertrieb_request: 'PE an Vertrieb wurde zur Übergabe freigegeben.',
      PE2Vertrieb_preRequestRejected: 'PE an Vertrieb wurde zurückgewiesen.',
      PE2Vertrieb_approved: 'PE an Vertrieb wurde angenommen.',
      PE2Vertrieb_rejected: 'PE an Vertrieb wurde abgelehnt.',
      decision_UmsetungsplanungBeantragt_JA: 'Es wurde eine Umsetzungsplanung beauftragt.',
      decision_UmsetungsplanungBeantragt_NEIN: 'Es wurde keine Umsetzungsplanung beauftragt.',
      decision_reactivate_UmsetungsplanungBeantragt: 'Das Projekt wurde reaktiviert.',
      Vertrieb2ENG_request: 'Die Übergabe von Vertrieb an ENG wurde angefragt.',
      Vertrieb2ENG_approved: 'Vertrieb an ENG wurde angenommen.',
      Vertrieb2ENG_rejected: 'Vertrieb an ENG wurde abgelehnt.',
      ENG2Vertrieb_request: 'Die Übergabe von ENG an Vertrieb wurde angefragt.',
      ENG2Vertrieb_rejected: 'ENG an Vertrieb wurde abgelehnt.',
      ENG2Vertrieb_approved: 'ENG an Vertrieb wurde angenommen.',
      decision_UmsetungsBeantragt_JA: 'Die Umsetzung wurde beauftragt.',
      decision_UmsetungsBeantragt_NEIN: 'Es wurde keine Umsetzung beauftragt.',
      decision_reactivate_UmsetungsBeantragt: 'Das Projekt wurde reaktiviert.',
      Projektleitung2SuW_preRequest: 'Eine überprüfung von Projektleitung an O&M wurde angefragt.',
      Vertrieb2Projektleitung_request: 'Die Übergabe von Vertrieb an Projektleitung wurde angefragt.',
      Vertrieb2Projektleitung_approved: 'Vertrieb an Projektleitung wurde angenommen.',
      Vertrieb2Projektleitung_rejected: 'Vertrieb an Projektleitung wurde abgelehnt.',
      Projektleitung2SuW_request: 'Die Übergabe von Projektleitung an O&M wurde angefragt.',
      Projektleitung2SuW_rejected: 'Projektleitung an O&M wurde abgelehnt.',
      Projektleitung2SuW_approved: 'Projektleitung an O&M wurde angenommen.',
      SuW_betrieb: 'Das Projekt wurde durch O&M in betrieb genommen.',
      returnFrom_SuW_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE.',
      returnFrom_SuW_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb.',
      returnFrom_SuW_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG.',
      returnFrom_SuW_TO_ENG2Vertrieb: 'Rückgabe zu ENG an Vertrieb.',
      returnFrom_SuW_TO_Vertrieb2Projektleitung: 'Rückgabe zu Vertrieb an Projektleitung.',
      returnFrom_SuW_TO_Projektleitung2SuW: 'Rückgabe zu Projektleitung an O&M.',
      returnFrom_Projektleitung2SuW_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE.',
      returnFrom_Projektleitung2SuW_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb.',
      returnFrom_Projektleitung2SuW_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG.',
      returnFrom_Projektleitung2SuW_TO_ENG2Vertrieb: 'Rückgabe zu ENG an Vertrieb.',
      returnFrom_Projektleitung2SuW_TO_Vertrieb2Projektleitung: 'Rückgabe zu Vertrieb an Projektleitung.',
      returnFrom_Vertrieb2Projektleitung_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE.',
      returnFrom_Vertrieb2Projektleitung_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb.',
      returnFrom_Vertrieb2Projektleitung_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG.',
      returnFrom_Vertrieb2Projektleitung_TO_ENG2Vertrieb: 'Rückgabe zu ENG an Vertrieb.',
      returnFrom_ENG2Vertrieb_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE.',
      returnFrom_ENG2Vertrieb_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb.',
      returnFrom_ENG2Vertrieb_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG.',
      returnFrom_Vertrieb2ENG_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE.',
      returnFrom_Vertrieb2ENG_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb.',
      returnFrom_PE2Vertrieb_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE.',
    }),

    btnTxt_decision_standortkonzept_NEIN: 'Es wurde weder eine Umsetzungsplanung noch ein Standortkonzept verkauft',
    btnTxt_decision_standortkonzept_JA: 'Es wurde eine Umsetzungsplanung oder ein Standortkonzept verkauft',
    btnTxt_decision_DCS_LIS_B_NEIN: 'DC LIS ist nicht Bestandteil',
    btnTxt_decision_DCS_LIS_B_JA: 'DC LIS ist Bestandteil',
    btnTxt_decision_dienstleitungOrIbnKonfig_JA: 'Es wurde neben IBN oder Konfig eine oder mehrere weitere Dienstleistungen verkauft.',
    btnTxt_decision_dienstleitungOrIbnKonfig_NEIN: 'Es wurde nur IBN und/oder Konfig verkauft.',
    btnTxt_decision_DCS_LIS_A_NEIN: 'DC LIS ist nicht Bestandteil',
    btnTxt_decision_DCS_LIS_A_JA: 'DC LIS ist Bestandteil',
    btnTxt_decision_DL_NEIN: 'Es wurde keine DL verkauft',
    btnTxt_decision_DL_JA: 'Es wurde eine DL verkauft',
    btnTxt_decision_Standortkonzept_JA: 'Es wurde ein Standortkonzept verkauft',
    btnTxt_decision_Standortkonzept_NEIN: 'Es wurde kein Standortkonzept verkauft',
    btnTxt_Vertrieb2PE_request: 'Übergabe beantragen',
    btnTxt_Vertrieb2PE_approved: 'Übergabe annehmen',
    btnTxt_Vertrieb2PE_rejected: 'Übergabe ablehnen',
    btnTxt_PE2Vertrieb_preRequest: 'Überprüfung Beantragen',
    btnTxt_PE2Vertrieb_request: 'Übergabe beantragen',
    btnTxt_PE2Vertrieb_preRequestRejected: 'Überprüfung zurückweisen',
    btnTxt_PE2Vertrieb_approved: 'Übernahme annehmen',
    btnTxt_PE2Vertrieb_rejected: 'Übernahme ablehnen',
    btnTxt_decision_UmsetungsplanungBeantragt_JA: 'Es wurde eine Umsetzungsplanung beauftragt',
    btnTxt_decision_UmsetungsplanungBeantragt_NEIN: 'Es wurde keine Umsetzungsplanung beauftragt',
    btnTxt_decision_reactivate_UmsetungsplanungBeantragt: 'Zurück zur Frage',
    btnTxt_Vertrieb2ENG_request: 'Übergabe beantragen',
    btnTxt_Vertrieb2ENG_approved: 'Übergabe annehmen',
    btnTxt_Vertrieb2ENG_rejected: 'Übergabe ablehnen',
    btnTxt_ENG2Vertrieb_request: 'Übergabe beantragen',
    btnTxt_ENG2Vertrieb_rejected: 'Übergabe ablehnen',
    btnTxt_ENG2Vertrieb_approved: 'Übergabe annehmen',
    btnTxt_decision_UmsetungsBeantragt_JA: 'Die Umsetzung wurde beauftragt',
    btnTxt_decision_UmsetungsBeantragt_NEIN: 'Es wurde keine Umsetzung beauftragt',
    btnTxt_decision_reactivate_UmsetungsBeantragt: 'Zurück zur Frage',
    btnTxt_Vertrieb2Projektleitung_request: 'Übergabe beantragen',
    btnTxt_Vertrieb2Projektleitung_approved: 'Übergabe annehmen',
    btnTxt_Vertrieb2Projektleitung_rejected: 'Übergabe ablehnen',
    btnTxt_Projektleitung2SuW_preRequest: 'Überprüfung Beantragen',
    btnTxt_Projektleitung2SuW_preRequestRejected: 'Überprüfung zurückweisen',
    btnTxt_Projektleitung2SuW_request: 'Übergabe beantragen',
    btnTxt_Projektleitung2SuW_rejected: 'Übergabe ablehnen',
    btnTxt_Projektleitung2SuW_approved: 'Übergabe annehmen',
    btnTxt_SuW_betrieb: 'Projekt in Betrieb stellen',

    btnTxt_returnFrom_Vertrieb2PE: 'Zurückgeben',
    btnTxt_returnFrom_PE2Vertrieb: 'Zurückgeben',
    btnTxt_returnFrom_Vertrieb2ENG: 'Zurückgeben',
    btnTxt_returnFrom_ENG2Vertrieb: 'Zurückgeben',
    btnTxt_returnFrom_Vertrieb2Projektleitung: 'Zurückgeben',
    btnTxt_returnFrom_Projektleitung2SuW: 'Zurückgeben',
    btnTxt_returnFrom_SuW2Running: 'Zurückgeben',
    btnTxt_returnFrom: 'Zurückgeben',

    missingRights_decision_standortkonzept_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_standortkonzept_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_DCS_LIS_B_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_DCS_LIS_B_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_dienstleitungOrIbnKonfig_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_dienstleitungOrIbnKonfig_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_DCS_LIS_A_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_DCS_LIS_A_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_DL_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_DL_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_Standortkonzept_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_Standortkonzept_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_UmsetungsplanungBeantragt_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_UmsetungsplanungBeantragt_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_UmsetungsBeantragt_JA: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_reactivate_UmsetungsplanungBeantragt: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_UmsetungsBeantragt_NEIN: 'Fehlende Rechte für Endscheidung',
    missingRights_decision_reactivate_UmsetungsBeantragt: 'Fehlende Rechte',
    missingRights_Vertrieb2PE_request: 'Fehlende Rechte für anfrage',
    missingRights_Vertrieb2PE_approved: 'Fehlende Rechte zum annehmen',
    missingRights_Vertrieb2PE_rejected: 'Fehlende Rechte zum ablehnen',
    missingRights_PE2Vertrieb_preRequest: 'Fehlende Rechte zur Überprüfungsanfrage',
    missingRights_PE2Vertrieb_request: 'Fehlende Rechte für anfrage',
    missingRights_PE2Vertrieb_preRequestRejected: 'Fehlende Rechte zum Zurückweisen',
    missingRights_PE2Vertrieb_approved: 'Fehlende Rechte zum annehmen',
    missingRights_PE2Vertrieb_rejected: 'Fehlende Rechte zum ablehnen',
    missingRights_Vertrieb2ENG_request: 'Fehlende Rechte für anfrage',
    missingRights_Vertrieb2ENG_approved: 'Fehlende Rechte zum annehmen',
    missingRights_Vertrieb2ENG_rejected: 'Fehlende Rechte zum ablehnen',
    missingRights_ENG2Vertrieb_request: 'Fehlende Rechte für anfrage',
    missingRights_ENG2Vertrieb_approved: 'Fehlende Rechte zum annehmen',
    missingRights_ENG2Vertrieb_rejected: 'Fehlende Rechte zum ablehnen',
    missingRights_Vertrieb2Projektleitung_request: 'Fehlende Rechte für anfrage',
    missingRights_Vertrieb2Projektleitung_approved: 'Fehlende Rechte zum annehmen',
    missingRights_Vertrieb2Projektleitung_rejected: 'Fehlende Rechte zum ablehnen',
    missingRights_Projektleitung2SuW_preRequest: 'Fehlende Rechte',
    missingRights_Projektleitung2SuW_request: 'Fehlende Rechte für anfrage',
    missingRights_Projektleitung2SuW_preRequestRejected: 'Fehlende Rechte',
    missingRights_Projektleitung2SuW_approved: 'Fehlende Rechte zum annehmen',
    missingRights_Projektleitung2SuW_rejected: 'Fehlende Rechte zum ablehnen',
    missingRights_SuW_betrieb: 'Fehlende Rechte zur Inbetriebnahme',
    missingRights_SuW_archive: 'Fehlende Rechte zur Archivierung',
    missingRights_SuW_unarchive: 'Fehlende Rechte um Archivierung zurückzuziehen',
    missingRights_returnFrom_Vertrieb2PE: 'Fehlende Rechte für Rückgabe',
    missingRights_returnFrom_PE2Vertrieb: 'Fehlende Rechte für Rückgabe',
    missingRights_returnFrom_Vertrieb2ENG: 'Fehlende Rechte für Rückgabe',
    missingRights_returnFrom_ENG2Vertrieb: 'Fehlende Rechte für Rückgabe',
    missingRights_returnFrom_Vertrieb2Projektleitung: 'Fehlende Rechte für Rückgabe',
    missingRights_returnFrom_Projektleitung2SuW: 'Fehlende Rechte für Rückgabe',
    missingRights_returnFrom_SuW2Running: 'Fehlende Rechte für Rückgabe',


    dropdown_returnTo_Vertrieb2PE: 'Rückgabe zu „Vertrieb an PE“',
    dropdown_returnTo_PE2Vertrieb: 'Rückgabe zu „PE an Vertrieb“',
    dropdown_returnTo_Vertrieb2ENG: 'Rückgabe zu „Vertrieb an ENG“',
    dropdown_returnTo_ENG2Vertrieb: 'Rückgabe zu „ENG an Vertrieb“',
    dropdown_returnTo_Vertrieb2Projektleitung: 'Rückgabe zu „Vertrieb an Projektleitung“',
    dropdown_returnTo_Projektleitung2SuW: 'Rückgabe zu „Projektleitung an O&M',
    dropdown_returnTo_SuW2Running: 'Rückgabe zu „O&M an Running“',

    tabTitle_Vertrieb2PE: 'Vertrieb an PE',
    tabTitle_PE2Vertrieb: 'PE an Vertrieb',
    tabTitle_Vertrieb2ENG: 'Vertrieb an ENG',
    tabTitle_ENG2Vertrieb: 'ENG an Vertrieb',
    tabTitle_Vertrieb2Projektleitung: 'Vertrieb an Projektleitung',
    tabTitle_Projektleitung2SuW: 'Projektleitung an O&M',
    tabTitle_SuW2Running: 'Inbetriebnahme',

    uebergabeTitle_Vertrieb2PE: 'Vertrieb an PE',
    uebergabeTitle_PE2Vertrieb: 'PE an Vertrieb',
    uebergabeTitle_Vertrieb2ENG: 'Vertrieb an ENG',
    uebergabeTitle_ENG2Vertrieb: 'ENG an Vertrieb',
    uebergabeTitle_Vertrieb2Projektleitung: 'Vertrieb an Projektleitung',
    uebergabeTitle_Projektleitung2SuW: 'Projektleitung an O&M',
    uebergabeTitle_SuW2Running: 'Inbetriebnahme',

    returnFrom_SuW_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE',
    returnFrom_SuW_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb',
    returnFrom_SuW_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG',
    returnFrom_SuW_TO_ENG2Vertrieb: 'Rückgabe zu ENG an Vertrieb',
    returnFrom_SuW_TO_Vertrieb2Projektleitung: 'Rückgabe zu Vertrieb an Projektleitung',
    returnFrom_SuW_TO_Projektleitung2SuW: 'Rückgabe zu Projektleitung an O&M',
    returnFrom_Projektleitung2SuW_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE',
    returnFrom_Projektleitung2SuW_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb',
    returnFrom_Projektleitung2SuW_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG',
    returnFrom_Projektleitung2SuW_TO_ENG2Vertrieb: 'Rückgabe zu ENG an Vertrieb',
    returnFrom_Projektleitung2SuW_TO_Vertrieb2Projektleitung: 'Rückgabe zu Vertrieb an Projektleitung',
    returnFrom_Vertrieb2Projektleitung_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE',
    returnFrom_Vertrieb2Projektleitung_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb',
    returnFrom_Vertrieb2Projektleitung_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG',
    returnFrom_Vertrieb2Projektleitung_TO_ENG2Vertrieb: 'Rückgabe zu ENG an Vertrieb',
    returnFrom_ENG2Vertrieb_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE',
    returnFrom_ENG2Vertrieb_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb',
    returnFrom_ENG2Vertrieb_TO_Vertrieb2ENG: 'Rückgabe zu Vertrieb an ENG',
    returnFrom_Vertrieb2ENG_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE',
    returnFrom_Vertrieb2ENG_TO_PE2Vertrieb: 'Rückgabe zu PE an Vertrieb',
    returnFrom_PE2Vertrieb_TO_Vertrieb2PE: 'Rückgabe zu Vertrieb an PE',

    uebergabeAction_request: "Übergabe angefragt",
    uebergabeAction_preRequest: "Überprüfung",
    uebergabeAction_preRequestRejected: "Überprüfung abgelehnt",
    uebergabeAction_approved: "Übergabe angenommen",
    uebergabeAction_rejected: "Übergabe abgelehnt",
    uebergabeAction_returnFrom: "Zurückgegeben",
  }
}

export type TranslationMap = ReturnType<typeof BuildTranslationMap>
