import { Div } from '@expo/html-elements'
import { Text } from '../../TextComponents'
import { dsv } from '../../../styles/defaults'
import { DataListColumnHeader } from './DataListColumnHead'
import { DataListData, headerFieldStyles } from './dataAndTypes'

export type DataListColumnProps<
  TData extends DataListData<TKeys>,
  TKeys extends string
> = {
  headerHeight: number,
  headerPadding: number,
  field: TKeys,
  data: Array<TData>,
  sorted: undefined | "asc" | "desc",
  removeField?: (field: TKeys) => void,
  changeSortForField: (field: TKeys) => void,
  formatData: (field: TKeys, data: TData) => JSX.Element | string
}

export function DataListColumn<
  TData extends DataListData<TKeys>,
  TKeys extends string
>(
  {
    data,
    field,
    headerHeight,
    headerPadding,
    sorted,
    removeField,
    changeSortForField,
    formatData,
  }: DataListColumnProps<TData, TKeys>
) {

  return (
    <Div key={field} style={{ flexGrow: 1, ...(headerFieldStyles[field as keyof typeof headerFieldStyles] ?? {}) }}>
      <DataListColumnHeader
        field={field}
        headerHeight={headerHeight}
        headerPadding={headerPadding}
        sorted={sorted}
        removeField={removeField}
        changeSortForField={changeSortForField}
      />
      <Div style={{ marginTop: 4 }}>
        {data.map((rowData, rowIdx) => (
          <a
            key={rowData.id}
            href={`/projects/${rowData.id}`}
            style={{
              alignContent: 'center',
              height: 48,
              width: "100%",
              margin: 1,
              textDecoration: 'none',
              backgroundColor: rowIdx % 2 === 0 ? dsv.colors.connectGrau7 : dsv.colors.connectGrau7,
            }}
          >
            <Text style={{ paddingLeft: 12 }}>{formatData(field, rowData)}</Text>
          </a>
        ))}
      </Div>
    </Div>
  )
}
