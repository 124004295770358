import { Route, Routes } from '../utils/routing'

import { PDBLayout } from '../pages/pdb/pdb.layout'
import { Mitarbeiter } from '../pages/pdb/Mitarbeiter'
import { Notifications } from '../pages/pdb/Notifications'
import { CreateProject } from '../pages/pdb/CreateProject'
import { SetRequiredFields } from '../pages/pdb/SetRequiredFields'

import { ProjectsLayout } from '../pages/pdb/projects/Project.layout'
import { ProjectsHome } from '../pages/pdb/ProjectsHome'
import { ProjectsProjectDatenblatt } from '../pages/pdb/projects/ProjectDatenblatt'

import { ErrorLayout } from '../pages/error/Error.layout'
import { GeneralError } from '../pages/error/GeneralError'
import { Login } from '../pages/auth/Login'
import { ProjectOverview } from '../pages/pdb/projects/ProjectOverview'
import { MyProjects } from '../pages/pdb/MyProjects'
import { ProjectProducts } from '../pages/pdb/projects/ProjectProducts'
import { ProjectsProjectConfigOrder } from '../pages/pdb/projects/orders/ProjectsProjectConfigOrder'
import { ProjectsProjectBackendOrder } from '../pages/pdb/projects/orders/ProjectsProjectBackendOrder'
import { Uebergabe } from '../pages/pdb/projects/Uebergabe'

export function Router() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PDBLayout />}>
          <Route index element={<MyProjects />} />
          <Route path="myProjects" element={<MyProjects />} />
          <Route path="createProject" element={<CreateProject />} />
          <Route path="requiredFields" element={<SetRequiredFields />} />

          <Route path="projects">
            <Route index element={<ProjectsHome />} />
            <Route path=":projectId" element={<ProjectsLayout />}>
              <Route index element={<ProjectOverview />} />
              <Route path="state" element={<ProjectOverview />} />
              <Route path="products" element={<ProjectProducts />} />

              <Route path="vertrieb2pe" element={<Uebergabe uebergabeTyp="Vertrieb2PE" />} />
              <Route path="pe2vertrieb" element={<Uebergabe uebergabeTyp="PE2Vertrieb" />} />
              <Route path="vertrieb2eng" element={<Uebergabe uebergabeTyp="Vertrieb2ENG" />} />
              <Route path="eng2vertrieb" element={<Uebergabe uebergabeTyp="ENG2Vertrieb" />} />
              <Route path="vertrieb2projektleitung" element={<Uebergabe uebergabeTyp="Vertrieb2Projektleitung" />} />
              <Route path="projektleitung2suw" element={<Uebergabe uebergabeTyp="Projektleitung2SuW" />} />

              <Route path="orderBackend" element={<ProjectsProjectBackendOrder />} />
              <Route path="orderConfig" element={<ProjectsProjectConfigOrder />} />
              <Route path="datasheet" element={<ProjectsProjectDatenblatt />} />
            </Route>
          </Route>
          <Route path="mitarbeiter" element={<Mitarbeiter />} />
          <Route path="notifications" element={<Notifications />} />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="*" element={<GeneralError />} />
        </Route>
      </Routes>
    </>
  )
}
