import { StyleSheet } from 'react-native'

import { Table, TBody, TR, THead } from '@expo/html-elements'

import { dsv } from '../styles/defaults'
import { TH, TD, H4 } from './TextComponents'

import { TriggeredNotificationsSchema, TriggeredNotifications } from '@dpa/common/dist'
import { trpc } from '../../App'
import { useMemo } from 'react'

const tableStyle = StyleSheet.create({
  td: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 140,
  },
  th: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 140,
  },
  cellBorder: {
    borderBottomWidth: 1,
    borderBottomColor: dsv.colors.connectGrau7,
    borderBottomStyle: 'solid',
    borderSpacing: 10,
  },
})

function useNotificationList() {
  const response = trpc.userData.userNotificationList.useQuery()
  return useMemo(
    () => {
      const parsedResponse = TriggeredNotificationsSchema.array().safeParse(response.data)

      if (parsedResponse.success) {
        return {
          userNotificationList: parsedResponse.data,
          isLoading: false,
        }
      }
      return {
        userNotificationList: undefined,
        isLoading: response.isLoading,
      }
    },
    [response]
  )

}

export function UserNotificationsLog() {
  const { userNotificationList } = useNotificationList()

  const userNotificationLogItem = useMemo(
    () => {
      if (userNotificationList != null && userNotificationList.length > 0) {
        return userNotificationList.map((userNotificationItem) => { return <UserNotificationLogItem key={userNotificationItem.id} item={userNotificationItem} /> })
      } else {
        return <TR><TD colSpan={4}>Keine Einträge gefunden</TD></TR>
      }
    },
    [userNotificationList]
  )

  return (
    <>
      <Table
        style={{
          backgroundColor: dsv.colors.connectGrau7,
          borderCollapse: 'collapse',
          marginVertical: 12,
          width: '100%',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR style={tableStyle.cellBorder}>
            <TH style={[tableStyle.th]}>
              <H4>Abgesendet am</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Inhalt</H4>
            </TH>
          </TR>
        </THead>
        <TBody>
          {userNotificationLogItem}
        </TBody>
      </Table>
    </>
  )
}

export interface NotificationLogItemProps {
  item: TriggeredNotifications
}

export function UserNotificationLogItem({ item }: NotificationLogItemProps) {
  return (
    <TR style={tableStyle.cellBorder}>
      <TD style={tableStyle.td}>
        {`${item.creationData.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} ${item.creationData.toLocaleDateString()}`}
      </TD>
      <TD style={tableStyle.td}>
        {item.bodyTxt}
      </TD>
    </TR>
  )
}
